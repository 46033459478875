const FIELDS = {
  NAME_RU: 'NameRu',
  COUNTRY_NAME_RU: 'CountryNameRu',
  NAME_EN: 'NameEn',
  COUNTRY_NAME_EN: 'CountryNameEn',
  COUNTRY_CODE: 'CountryCode',
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
  RADIUS: 'Radius',
  COORDINATES: 'Coordinates',

  FORM_DATA: 'formData',
  VALIDATION: 'validation',
  IS_VALID_FORM: 'isValidForm',
  IS_NO_EDIT: 'isNoEdit',
  VALUE: 'value',
  SEARCH: {
    VALUE: 'search.value',
    SUGGEST: 'search.suggest',
  },
};

const TEXT = {
  BUTTONS: {
    DELETE: 'Удалить из дампа',
    RESTORE: 'Восстановить',
    EDIT: 'Редактировать',
    SAVE: 'Сохранить',
    ADD: 'Добавить отель',
    YES: 'Да',
  },
};

const LABELS_FIELDS_FORM = {
  NAME_RU: 'Регион',
  COUNTRY_NAME_RU: 'Страна',
  NAME_EN: 'Регион en',
  COUNTRY_NAME_EN: 'Страна en',
  COUNTRY_CODE: 'Код Страны',
  LATITUDE: 'Широта',
  LONGITUDE: 'Долгота',
  RADIUS: 'Радиус',
  COORDINATES: 'Координаты',
};

const FIELDS_TYPES = {
  INPUT: 'input',
  SELECT: 'select',
};

export {
  FIELDS,
  TEXT,
  LABELS_FIELDS_FORM,
  FIELDS_TYPES,
};
