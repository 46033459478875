const TRANSFERFIELDS = {
  DateArrival: 'DateArrival',
  DateDeparture: 'DateDeparture',
  ArrivalNumber: 'ArrivalNumber',
  DepartureNumber: 'DepartureNumber',
  LocationAddress: 'LocationAddress',
  DestinationAddress: 'DestinationAddress',
  Table: 'Table',
  Note: 'Note',
};

const TRANSFERFILTERCLASS = {
  Standart: 'Standart',
  Comfort: 'Economy',
  ComfortSecond: 'Comfort',
  Business: 'Business',
  BusinessComfort: 'BusinessComfort',
  Minivan: 'Minivan',
  Minibus: 'Minibus',
  Executive: 'Executive',
  TollRoadStandart: 'TollRoadStandart',
  TollRoadComfort: 'TollRoadComfort',
  BusinessLite: 'BusinessLite',
  TollRoadBusiness: 'TollRoadBusiness',
  MinivanVip: 'MinivanVip',
  MiniBus: 'MiniBus',
  LargeMiniBus: 'LargeMiniBus',
  Bus: 'Bus',
};

const MAPCARCLASS_RU = {
  All: 'все',
  Undefined: 'не определен',
  [TRANSFERFILTERCLASS.Standart]: 'стандарт',
  [TRANSFERFILTERCLASS.Comfort]: 'комфорт',
  [TRANSFERFILTERCLASS.ComfortSecond]: 'комфорт',
  [TRANSFERFILTERCLASS.Business]: 'бизнес',
  [TRANSFERFILTERCLASS.BusinessComfort]: 'бизнес комфорт',
  [TRANSFERFILTERCLASS.Minivan]: 'минивен',
  [TRANSFERFILTERCLASS.Minibus]: 'микроавтобус',
  [TRANSFERFILTERCLASS.Executive]: 'представительский',
  [TRANSFERFILTERCLASS.TollRoadStandart]: 'стандарт (по платной дороге)',
  [TRANSFERFILTERCLASS.TollRoadComfort]: 'комфорт (по платной дороге)',
  [TRANSFERFILTERCLASS.BusinessLite]: 'бизнес лайт',
  [TRANSFERFILTERCLASS.TollRoadBusiness]: 'бизнес (по платной дороге)',
  [TRANSFERFILTERCLASS.MinivanVip]: 'минивен VIP',
  [TRANSFERFILTERCLASS.MiniBus]: 'микроавтобус',
  [TRANSFERFILTERCLASS.LargeMiniBus]: 'микроавтобус, 18 мест',
  [TRANSFERFILTERCLASS.Bus]: 'автобус',
};

const MAPCARCLASS = {
  Undefined: '',
  Standart: 'standard',
  Comfort: 'economy',
  Business: 'business',
  BusinessComfort: 'business',
  Minivan: 'minivan',
  Minibus: 'minibus',
  Executive: 'representative',
};

const CLASS_MODELS = {
  Standart: 'Skoda Octavia, Ford Focus, Volkswagen Jetta и подобные',
  Comfort: 'Ford Mondeo, Hyundai i40, Renault Fluence и подобные',
  Business: 'Toyota Camry и подобные',
  BusinessComfort: 'Mercedes-Benz E-class',
  Executive: 'Mercedes-Benz S-class, BMW 7, Audi A8 и подобные',
};

const FIELDSLABELS = {
  DateArrival: 'Время подачи авто',
  DateDeparture: 'Время вылета самолета или отправления поезда',
  ArrivalNumber: 'Номер рейса прилета или поезда прибытия',
  DepartureNumber: 'Номер рейса вылета или поезда отправления',
  LocationAddress: 'Адрес места (номер вагона) подачи автомобиля',
  DestinationAddress: 'Адрес места окончания поездки',

  DateDepartureAirline: 'Дата и время вылета самолета',
  DateDepartureRailway: 'Дата и время отправления поезда',
  ArrivalNumberAirline: 'Номер рейса прилета',
  ArrivalNumberRailway: 'Номер поезда прибытия',
  DepartureNumberAirline: 'Номер рейса вылета',
  DepartureNumberRailway: 'Номер поезда отправления',

  Table: 'Надпись на табличке встречающего',
  Note: 'Дополнительный комментарий',
};

const PLACETYPE = {
  AIRPORT: 'Airport',
  RAILSTATION: 'RailwayStation',
  HOTEL: 'Hotel',
  CITY: 'Address',
};

const TRANSFERPROVIDERS = {
  IWAY: 'iway',
  VGT: 'VGT',
  VIPSKY: 'vipsky',
};

export {
  TRANSFERFIELDS as default,
  TRANSFERFIELDS,
  TRANSFERFILTERCLASS,
  MAPCARCLASS_RU,
  MAPCARCLASS,
  CLASS_MODELS,
  FIELDSLABELS,
  PLACETYPE,
  TRANSFERPROVIDERS,
};
