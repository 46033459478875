const SEARCH = {
  DATE: 'Дата',
  START_DATE: 'Дата начала',
  END_DATE: 'Дата окончания',
  SEND: 'Отправить',
  DATE_TRAVEL: 'Дата заезда',
  OPEN: 'open',
  STARTDATE: 'startDate',
  ENDDATE: 'endDate',
  DOWNLOAD: 'Скачать',
};

export default SEARCH;
