import COMPONENTS from './components';

const LABEL_CONTRACTS_CODE = {
  ADD_CODE: 'ДОБАВИТЬ КОД',
  SAVE_CODE: 'СОХРАНИТЬ КОД',
  DELETE_CODE: 'УДАЛИТЬ КОД',
};

const FIELD_TEXT_HOTEL_CONTRACT = {
  PLACING: 'Размещение',
  BREAKFAST: 'Завтрак',
  COMMISSION: 'Наценка Smartway:',
  ROOM_TYPE_ID: 'Категория номера:',
  HOTEL_NAME: 'Название отеля:',
  NAME_DOC_RATE: 'Название рейта по договору:',
  NAME_BOOKING_RATE: 'Название рейта на букинге:',
  COST_PER_NIGHT: 'Стоимость за ночь',
  NDS: 'В т.ч. НДС',
  RATE_VALIDITY_PERIOD: 'Период действия рейта',
  TERM_OF_CONTRACT: 'Срок договора',
  USING_TRAVELINE: 'Через TravelLine',
  ADD_PERIOD: '+ Добавить новый период',
  DELETE_PERION: '- Удалить',
};

const REQUIRED_EXTENSIONS = ['pdf', 'xlsx', 'jpeg', 'png', 'jpg', 'doc', 'docx', 'xml'];

const REQUIR_EXTENSION_XLSX = ['xlsx'];

const UPLOAD_FORM = {
  DESCRIPTION: 'Чтобы начать загрузку, перетащите файл сюда\nили',
  INVALID_EXTENSION: 'Некорректное расширение файла. Доступные для загрузки:',
  CHOOSE_FILE: 'Выберите файл',
};

const FIELDS = {
  ACTIVE_HOTEL_CONTRACT: 'activeHotelContract',
  HOTEL_NAME_AUTOCOMPLETE: {
    SUGGEST: 'hotelNameAutocomplete.suggests',
    LABEL: 'hotelNameAutocomplete.label',
  },
  ACTIVE_HOTEL_CONTRACT_FIELDS: {
    RATES: 'activeHotelContract.Rates',
  },
  RATES: 'Rates',
  COMPARE: 'Compares',
  VALIDATION: 'validation',
  HOTEL_NAME: 'HotelName',
  HOTEL_ID: 'HotelId',
  BOOKING_ID: 'BookingId',
  CITY: 'City',
  PAX: 'Pax',
  BREAKFAST: 'Breakfast',
  COMMISSION: 'Commission',
  DESCRIPTION: 'Description',
  IS_PER_SENT: 'IsPercent',
  ROOM_TYPE_ID: 'RoomTypeId',
  RATE_NAME: 'RateName',
  BOOKING_ROOM_NAMES: 'BookingRoomNames',
  VAT: 'Vat',
  REFUNDABLE_HOURS: 'RefundableHours',
  PRICE: 'Price',
  IMAGES: 'Images',
  END: 'End',
  START: 'Start',
  START_LIFE_TIME: 'StartLifeTime',
  END_LIFE_TIME: 'EndLifeTime',
  PERIODS_RATE: 'Periods',
  ACTIVE_AVIA_CONTRACT: 'activeAviaContract',
  Iata: 'Iata',
  Number: 'Number',
  IsDiscount: 'IsDiscount',
  USER_ACTIVE_DATA: {
    SHOW_USER_APPROVE_DIALOG: 'showUserApproveDialog',
    SHOW_USER_RIGHTS_DIALOG: 'showUserRightsDialog',
  },
};

const PLACING_SELECT = [
  { name: 'одноместное', cost: 1 },
  { name: 'двухместное', cost: 2 },
];

const BREAKFAST_SELECT = [
  { name: 'не включен', cost: 0 },
  { name: 'включен', cost: 1 },
];

const COMMISSION_SELECT = [
  { name: 'руб', cost: 0 },
  { name: '%', cost: 1 },
];

const NDS_SELECT = [
  { name: 'без НДС', cost: 0, vatRate: 0 },
  { name: '20%', cost: 1, vatRate: 20 },
  { name: '0%', cost: 2, vatRate: 0 },
];

const ERROR_MESSAGE = {
  SAVE: 'Не удалось сохранить. Обратитесь в поддержку',
  GET: 'Не удалось удалить.Обратитесь в поддержку ',
  TOOLTIP_ERROR: 'Запрещено использовать корпоративные бонусные',
};

const LABELS_HOTEL_CONTRACT = {
  ADD_CONTRACT: 'ДОБАВИТЬ ДОГОВОР',
  SAVE_CONTRACT: 'СОХРАНИТЬ ДОГОВОР',
  SAVE_DRAFT: 'СОХРАНИТЬ ЧЕРНОВИК',
  COPY_CONTRACT: 'КОПИРОВАТЬ ДОГОВОР',
  COPIED_CONTRACT: 'СКОПИРОВАННО',
  EDIT_CONTRACT: 'РЕДАКТИРОВАТЬ ДОГОВОР',
  SHOW_CONTRACT: 'ПОСМОТРЕТЬ ДОГОВОР',
  DRAFT_CONTRACT: 'РЕДАКТИРОВАТЬ ЧЕРНОВИК',
  DELETE_CONTRACT: 'УДАЛИТЬ ДОГОВОР',
  ADD_RATE: 'ДОБАВИТЬ РЕЙТ',
  COPY_RATE: 'КОПИРОВАТЬ РЕЙТ',
  DELETE_RATE: 'УДАЛИТЬ РЕЙТ',
  CANCEL: 'ОТМЕНИТЬ',
  DESCRIBE_NUMBER: 'Опишите номер',
  DESCRIPTION_ROOM: 'Описание номера',
  ADD_NAME: 'добавить название',
  DOWNLOAD: 'Скачать',
  PROMPT: 'Файл загружен. Хотите скачать?',
  NAME_RATE_AANDA: 'Название рейта в A&A',
  HOURS_TO_CHECKIN: 'часов до даты заезда',
  DATE_CANCELLATION: 'Дата бесплатной отмены:',
  DIFF_TIMES: 'Если местное время отеля отличается от московского:',
  DIFF_MORE: 'в Москве 14:00, в отеле 16:00 - бесплатная отмена за 22 часа вместо 24',
  DIFF_LOW: 'в Москве 14:00, в отеле 12:00 - бесплатная отмена за 26 часов вместо 24',
  FOR: 'за',
};

const VALID_FIELDS_RATE = [
  'RoomTypeId',
  'RateName',
  'Description',
  'Pax',
  'Breakfast',
  'Vat',
  'Periods',
];

const TYPE_SELECT = {
  RateName: COMPONENTS.INPUT.TYPE.TEXT,
  BookingRoomNames: COMPONENTS.INPUT.TYPE.TEXT,
  Commission: COMPONENTS.INPUT.TYPE.NUMBER,
  Price: COMPONENTS.INPUT.TYPE.NUMBER,
};

const INVALID_CONTRACT = {
  EMPTY: 'Введите валидный код',
  EMPTY_FOR_HOTEL: 'Поле не должно быть пустым',
  BOOKING: 'Отель не сопоставлен с букингом! Сохранить такой договор нельзя.',
  NUMBER: 'Введите целое число',
  SELECT_ON_LIST: 'Выберите из списка',
  FILE_UPLOAD_FAILED: 'Не удалось загрузить файл.',
  SALE_S7: 'Код должен иметь формат QUW1234',
  MILE_S7: 'Код должен иметь формат QY12345',
  SALE_AEROFLOT: 'Код должен иметь формат NSSA/CK2559',
  MILE_AEROFLOT: 'Код должен иметь формат 12345678',
  SALE_TURKISH: 'Код должен иметь формат 123456 или CCC22739',
  SALE_UT: 'Код должен иметь формат CC1234',
  MILE_U6: 'Код должен иметь формат 1234567890',
  SALE_U6: 'Код должен иметь буквенный формат',
  INVALID_DATE: 'Введен некорректный формат даты!',
  INVALID_START_DATE: 'Введена некорректная дата!',
  CROSSING_DATES_PERIODS: 'Обратите внимание: периоды действия рейтов пересекаются!',
};

const FIELDS_FORM = {
  COMPANY_TYPE_ID: 'CompanyTypeId',
  COMPANY_NAME: 'CompanyName',
  SHORT_COMPANY_NAME: 'ShortCompanyName',
  INN: 'INN',
  KPP: 'KPP',
  INDEX: 'Index',
  REGION_CODE: 'RegionCode',
  DISTRICT: 'District',
  CITY: 'City',
  LOCALITY: 'Locality',
  STREET: 'Street',
  STRUCTURE: 'Structure',
  HOUSING: 'Housing',
  ROOM: 'Room',
  CONTRACT_NUMBER: 'Number',
  CONTRACT_START_DATE: 'StartDate',
  CEO: 'CEO',
  LEGAL_ADDRESS: 'LegalAddress',
  ACTUAL_ADDRESS: 'ActualAddress',
  IIN_BIN_KZ: 'IINBIN',
};

const INVALID_REQUESTIES_FIELDS = {
  BIC: 'Введите БИК банка',
  INVOICE_NUMBER: 'Введите № счета',
  EMAIL: 'Введите email',
  BANK_DETAILS: 'Введите реквизиты банка',
  CORRESPONDENT_BILL: 'Введите № корр. счёта',
  FORMAT_BIC: 'Неправильный формат БИК банка',
  FORMAT_EMAIL: 'Неправильный формат Email',
  FORMAT_INVOICE_NUMBER: 'Неправильный формат № счета',
  FORMAT_CORRESPONDENT_BILL: 'Неправильный формат корр. счёта',
  FORMAT_ISD: 'Неправильный формат ИСД, введите числа',
};

const FIELDS_FORM_RU = {
  [FIELDS_FORM.COMPANY_NAME]: 'Имя компании',
  [FIELDS_FORM.SHORT_COMPANY_NAME]: 'Краткое имя',
  [FIELDS_FORM.INN]: 'ИНН',
  [FIELDS_FORM.KPP]: 'КПП',
  [FIELDS_FORM.INDEX]: 'Индекс',
  [FIELDS_FORM.REGION_CODE]: 'Код региона',
  [FIELDS_FORM.DISTRICT]: 'Район',
  [FIELDS_FORM.CITY]: 'Город',
  [FIELDS_FORM.LOCALITY]: 'Населенный пункт',
  [FIELDS_FORM.STREET]: 'Улица',
  [FIELDS_FORM.STRUCTURE]: 'Дом',
  [FIELDS_FORM.HOUSING]: 'Корпус',
  [FIELDS_FORM.ROOM]: 'Комната/офис',
  [FIELDS_FORM.CONTRACT_NUMBER]: 'Номер договора',
  [FIELDS_FORM.CONTRACT_START_DATE]: 'Дата договора',
  [FIELDS_FORM.CEO]: 'CEO',
  [FIELDS_FORM.LEGAL_ADDRESS]: 'Юридический адрес',
  [FIELDS_FORM.ACTUAL_ADDRESS]: 'Фактический адрес',
  [FIELDS_FORM.IIN_BIN_KZ]: 'ИИН/БИН',
};

const INVALID_PROFILE_INFO = {
  COMPANY_TYPE_ID: 'Выберите признак подаккаунта',
  COMPANY_NAME: 'Введите имя компании',
  INN: 'Введите ИНН',
  FORMAT_INN: 'Неправильный формат ИНН',
  CONTRACT_NUMBER: 'Введите номер договора',
  CONTRACT_DATE: 'Введите дату договора',
  KPP: 'Неправильный формат КПП',
  INDEX: 'Неправильный формат индекса',
  REGION: 'Неправильный формат региона',
  IINBIN: 'Введите корректный идентификатор',
};

const USER_FIELD = {
  REGISTRATION_NAME: 'RegistrationName',
  PHONE_NUMBER: 'PhoneNumber',
};

const USER_FIELD_RU = {
  [USER_FIELD.REGISTRATION_NAME]: 'Имя',
  [USER_FIELD.PHONE_NUMBER]: 'Номер',
};

const DEFAULT_AVIA_COMPANY_CONTRACT = [
  { name: 'S7', cost: 0, iata: 'S7' },
  { name: 'Аэрофлот', cost: 1, iata: 'SU' },
  { name: 'Уральские авиалинии', cost: 2, iata: 'U6' },
  { name: 'Турецкие авиалинии', cost: 3, iata: 'TK' },
  { name: 'UTair', cost: 4, iata: 'UT' },
];

const AVIA_COMPANY_CONTRACT = [
  { name: 'Аэрофлот', cost: 0, iata: 'SU' },
  { name: 'Уральские авиалинии', cost: 1, iata: 'U6' },
  { name: 'Турецкие авиалинии', cost: 2, iata: 'TK' },
  { name: 'UTair', cost: 3, iata: 'UT' },
];

const AVIA_COMPANY_CONTRACT_MILE = [
  { name: 'Аэрофлот', cost: 0, iata: 'SU' },
  { name: 'Уральские авиалинии', cost: 1, iata: 'U6' },
];

const AVIA_COMPANY_CONTRACT_S7 = [
  { name: 'S7', cost: 0, iata: 'S7' },
];

const AVIA_PROGRAMM_CONTRACT = [
  { name: 'Мильная', cost: 0 },
  { name: 'Скидочная', cost: 1 },
  { name: 'S7 Corp', cost: 2 },
];

const AVIA_CONTRACT_FIELDS = {
  PROGRAM: 'Программа',
  AVIA_COMPANY: 'Авиакомпания',
};

export {
  FIELD_TEXT_HOTEL_CONTRACT,
  PLACING_SELECT,
  BREAKFAST_SELECT,
  COMMISSION_SELECT,
  NDS_SELECT,
  REQUIRED_EXTENSIONS,
  REQUIR_EXTENSION_XLSX,
  FIELDS,
  UPLOAD_FORM,
  LABELS_HOTEL_CONTRACT,
  VALID_FIELDS_RATE,
  TYPE_SELECT,
  ERROR_MESSAGE,
  INVALID_CONTRACT,
  FIELDS_FORM,
  INVALID_REQUESTIES_FIELDS,
  FIELDS_FORM_RU,
  INVALID_PROFILE_INFO,
  USER_FIELD,
  USER_FIELD_RU,
  AVIA_COMPANY_CONTRACT,
  LABEL_CONTRACTS_CODE,
  AVIA_PROGRAMM_CONTRACT,
  AVIA_CONTRACT_FIELDS,
  AVIA_COMPANY_CONTRACT_MILE,
  AVIA_COMPANY_CONTRACT_S7,
  DEFAULT_AVIA_COMPANY_CONTRACT,
};
