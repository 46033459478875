import { TYPERATRATES } from './account';

const ALVISTA_PROVIDERS = {
  id: 1,
  type: TYPERATRATES.ORIGINAL,
  value: 'Alvist',
  label: 'Alvist',
};

const MY_MICE_AGENT = {
  id: 2,
  type: TYPERATRATES.ORIGINAL,
  value: 'Мой майс АГЕНТ',
  label: 'Мой майс АГЕНТ',
};

const MY_MICE = {
  id: 3,
  type: TYPERATRATES.ORIGINAL,
  value: 'Мой майс',
  label: 'Мой майс',
};

const PROVIDER = [
  ALVISTA_PROVIDERS,
  MY_MICE_AGENT,
  MY_MICE,
  {
    id: 0,
    type: TYPERATRATES.OPTIONAL,
    value: null,
    label: '',
  },
];

const PROVIDERS_MICE = [
  {
    id: Date.now(),
    type: TYPERATRATES.ORIGINAL,
    value: 'MyMiceAgent',
    label: 'Мой майс АГЕНТ',
  },
  {
    id: Date.now(),
    type: TYPERATRATES.ORIGINAL,
    value: 'MyMice',
    label: 'Мой майс',
  },
];

export {
  ALVISTA_PROVIDERS,
  PROVIDER,
  PROVIDERS_MICE,
};
