const REQUISITES_SMARTWAY = {
  companyName: 'ООО "СМАРТВЭЙ"',
  inn: '7714379242',
  kpp: '773101001',
  bik: '',
  invoiceNumber: '',
  email: 'help@smartway.today',
  bankDetails: 'БАНК ГПБ (АО) г. Москва',
  correspondentBill: '30101810200000000823',
};

export { REQUISITES_SMARTWAY };
