const STORE_NAMES = {
  WORKSPACE: 'Workspace',
  APP: 'App',
  FEATURE_FLAGS: 'FeatureFlags',
  NOTIFICATIONS: 'Notifications',
  FILTER: 'Filter',
  SEARCH_USERS: 'SearchUsers',
  HOTELS: 'Hotels',
  REGIONS: 'Regions',
  TRIP: 'Trip',
  TRIP_TRAIN: 'TripTrain',
};

export { STORE_NAMES };
