const STATISTICS_FIELDS = {
  PERCENTAGE_USED: 'PercentageUsed', // float32
  BUY_HOTELS: 'BuyHotels', // bool
  PACKAGES_1_C: 'Packages1C', // bool
  AEROFLOT_CODE: 'AeroflotCode', // CodeType
  S_7_CODE: 'S7Code', // CodeType
  CHOSE_PLACES: 'ChosePlaces', // bool
  ONE_TIME_CERTIFICATE: 'OneTimeCertificate', // bool
  SUBSCRIBE_TO_CERTIFICATE: 'SubscribeToCertificate', // bool
  MOBILE: 'Mobile', // bool
  VOIDING: 'Voiding', // bool
  INTEGRATION_API: 'IntegrationAPI', // bool
  TAXI: 'Taxi', // bool
  EXPENSE_REPORT: 'ExpenseReport', // bool
  TRAVEL_POLICIES: 'TravelPolicies', // bool
  APPROVAL_SCHEMES: 'ApprovalSchemes', // bool
  CUSTOM_CLOSING_SCHEME: 'CustomClosingScheme', // string
  USER_ANALYTICS_OR_COST_CENTERS: 'UserAnalyticsOrCostCenters', // bool
  INTEGRATION_1C: 'Integration1C', // bool
  REQUESTS_1C: 'Requests1C', // bool
  ACTIVE_USERS: 'ActiveUsers', // int
  MOBILE_AUTH: 'MobileAuth', // bool
  MOBILE_BOOKING: 'MobileBooking', // bool
  SENDING_TYPE_INFO: 'SendingTypeInfo', // string,
  AIRS_BOUGHT: 'AirsBought', // int
  AIRS_USED: 'AirsUsed', // int
  TRAINS_BOUGHT: 'TrainsBought', // int
  TRAINS_USED: 'TrainsUsed', // int
  HOTELS_BOUGHT: 'HotelsBought', // int
  HOTELS_USED: 'HotelsUsed', // int
  CUSTOM_BOUGHT: 'CustomsBought', // int
  CUSTOM_USED: 'CustomsUsed', // int
  TAXI_BOUGHT: 'TaxiBought', // int
  TAXI_USED: 'TaxiUsed', // int
  AEROEXPRESS_BOUGHT: 'AeroexpressBought', // int
  AEROEXPRESS_USED: 'AeroexpressUsed', // int
  FLIGHT_CERTIFICATE_BOUGHT: 'FlightCertificateBought', // int
  FLIGHT_CERTIFICATE_USED: 'FlightCertificateUsed', // int
  TRANSFER_BOUGHT: 'TransferBought', // int
  TRANSFER_USED: 'TransferUsed', // int
  VIP_HALL_BOUGHT: 'ViphallBought', // int
  VIP_HALL_USED: 'ViphallUsed', // int
};

const LABEL_STATISTICS_FIELDS = {
  [STATISTICS_FIELDS.USER_ANALYTICS_OR_COST_CENTERS]: 'Пользовательские аналитики/центры затрат:',
  [STATISTICS_FIELDS.BUY_HOTELS]: 'Покупает ли отели:',
  [STATISTICS_FIELDS.PACKAGES_1_C]: 'Отправленные, полученные пакеты в 1С:',
  [STATISTICS_FIELDS.AEROFLOT_CODE]: 'Корп. программа аэрофлот:',
  [STATISTICS_FIELDS.S_7_CODE]: 'Корп. программа S7:',
  [STATISTICS_FIELDS.CHOSE_PLACES]: 'Выбор мест:',
  [STATISTICS_FIELDS.ONE_TIME_CERTIFICATE]: 'Справки разовые:',
  [STATISTICS_FIELDS.SUBSCRIBE_TO_CERTIFICATE]: 'Подписка:',
  [STATISTICS_FIELDS.MOBILE]: 'Мобильное:',
  [STATISTICS_FIELDS.VOIDING]: 'Войдирование:',
  [STATISTICS_FIELDS.INTEGRATION_API]: 'Интеграция АПИ:',
  [STATISTICS_FIELDS.TAXI]: 'Такси:',
  [STATISTICS_FIELDS.EXPENSE_REPORT]: 'Авансовые отчеты:',
  [STATISTICS_FIELDS.TRAVEL_POLICIES]: 'Тревел политики:',
  [STATISTICS_FIELDS.APPROVAL_SCHEMES]: 'Схемы согласования:',
  [STATISTICS_FIELDS.CUSTOM_CLOSING_SCHEME]: 'Кастомная схема закрывающих:',
  [STATISTICS_FIELDS.PERCENTAGE_USED]: 'Процент использования сервиса:',
  [STATISTICS_FIELDS.INTEGRATION_1C]: 'Отправляли XML в 1С:',
  [STATISTICS_FIELDS.REQUESTS_1C]: '1С Заявки:',
  [STATISTICS_FIELDS.ACTIVE_USERS]: 'Количество активных пользователей:',
  [STATISTICS_FIELDS.MOBILE_AUTH]: 'Мобильное (авторизация):',
  [STATISTICS_FIELDS.MOBILE_BOOKING]: 'Мобильное (покупки):',
  [STATISTICS_FIELDS.SENDING_TYPE_INFO]: 'ЭДО:',
  [STATISTICS_FIELDS.AIRS_BOUGHT]: 'Авиабилеты - всего куплено:',
  [STATISTICS_FIELDS.AIRS_USED]: 'Авиабилеты - всего использовано:',
  [STATISTICS_FIELDS.TRAINS_BOUGHT]: 'ЖД билеты - всего куплено:',
  [STATISTICS_FIELDS.TRAINS_USED]: 'ЖД билеты - всего использовано:',
  [STATISTICS_FIELDS.HOTELS_BOUGHT]: 'Гостиницы - всего куплено:',
  [STATISTICS_FIELDS.HOTELS_USED]: 'Гостиницы - всего использовано:',
  [STATISTICS_FIELDS.CUSTOM_BOUGHT]: 'Кастом - всего куплено:',
  [STATISTICS_FIELDS.CUSTOM_USED]: 'Кастом - всего использовано:',
  [STATISTICS_FIELDS.TAXI_BOUGHT]: 'Такси - всего куплено:',
  [STATISTICS_FIELDS.TAXI_USED]: 'Такси - всего использовано:',
  [STATISTICS_FIELDS.AEROEXPRESS_BOUGHT]: 'Аэроэкспресс - всего куплено:',
  [STATISTICS_FIELDS.AEROEXPRESS_USED]: 'Аэроэкспресс - всего использовано:',
  [STATISTICS_FIELDS.FLIGHT_CERTIFICATE_BOUGHT]: 'Справка о совершении перелета - всего куплено:',
  [STATISTICS_FIELDS.FLIGHT_CERTIFICATE_USED]: 'Справка о совершении перелета - всего использовано:',
  [STATISTICS_FIELDS.TRANSFER_BOUGHT]: 'Трансфер - всего куплено:',
  [STATISTICS_FIELDS.TRANSFER_USED]: 'Трансфер - всего использовано:',
  [STATISTICS_FIELDS.VIP_HALL_BOUGHT]: 'VIP зал - всего куплено:',
  [STATISTICS_FIELDS.VIP_HALL_USED]: 'VIP зал - всего использовано:',
};

const BOOL_FIELD_VALUE = {
  YES: 'Да',
  NO: 'Нет',
};

const CODE_TYPE_FIELD = {
  MILE: 'Mile',
  DISCOUNT: 'Discount',
  NONE: 'None',
  DIFFERENT_CORPORATE_PROGRAMS: 'DifferentCorporatePrograms',
};

const CODE_TYPE_FIELD_VALUE = {
  [CODE_TYPE_FIELD.MILE]: 'Мильная',
  [CODE_TYPE_FIELD.DISCOUNT]: 'Скидочная',
  [CODE_TYPE_FIELD.NONE]: 'Нет',
  [CODE_TYPE_FIELD.DIFFERENT_CORPORATE_PROGRAMS]: 'Разные корпоративные программы на юридических лицах',
};

const BONUSES = {
  LABEL: 'Бонусы, подарки, лояльность',
  MAX_COMMENT_LENGTH: 4000,
  NO_COMMENT_TEXT: 'Нет комментариев',
  INPUT_COMMENT: 'Введите комментарий не более 4000 символов',
};

export {
  STATISTICS_FIELDS,
  LABEL_STATISTICS_FIELDS,
  BOOL_FIELD_VALUE,
  CODE_TYPE_FIELD_VALUE,
  BONUSES,
};

export const NONE = undefined;
