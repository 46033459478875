const RATETYPE = {
  HOTEL: 'Hotel',
  AIRLINE: 'Airline',
  TRAIN: 'Train',
  BUS: 'Bus',
};

const RATE = {
  APRIL2017: 'April2017',
  JULY2017: 'July2017',
  GENERAL2017: '250/190',
  FIXED99: 'Fixed99',
  NOFEENOCOMISSION: 'NoFeeNoComission',
  VARIABLE6PERCENT: 'Variable6Percent',
  VARIABLE8PERCENT: 'Variable8Percent',
  VARIABLE8PERCENTANDHALF: 'Variable8PercentAndHalf',
  VARIABLE10PERCENT: 'Variable10Percent',
  VARIABLE11PERCENT: 'Variable11Percent',
  VARIABLE11PERCENTANDHALF: 'Variable11PercentAndHalf',
  VARIABLE12PERCENT: 'Variable12Percent',
  VARIABLE15PERCENT: 'Variable15Percent',
  VARIABLE13PERCENTANDHALF: 'Variable13PercentAndHalf',
  CUSTOM: 'Custom',
  DEFAULT: 'Default',
  SEVENDAYS190AND160: 'SevenDays190And160',
  THIRTYDAYSPLUS2PERCENT: 'ThirtyDaysPlus2Percent',
  FOURTEENDAYS250AND190: 'FourteenDays250And190',

};

const RURATE = {
  [RATE.APRIL2017]: '250/190',
  [RATE.JULY2017]: '250/190',
  [RATE.GENERAL2017]: '250/190',
  [RATE.FIXED99]: 'Предоплата 99',
  [RATE.NOFEENOCOMISSION]: 'Предоплата 0',
  [RATE.VARIABLE6PERCENT]: '6%',
  [RATE.VARIABLE8PERCENT]: '8%',
  [RATE.VARIABLE8PERCENTANDHALF]: '8,5%',
  [RATE.VARIABLE10PERCENT]: '10%',
  [RATE.VARIABLE11PERCENT]: '11%',
  [RATE.VARIABLE11PERCENTANDHALF]: '11,5%',
  [RATE.VARIABLE12PERCENT]: '12%',
  [RATE.VARIABLE15PERCENT]: '15%',
  [RATE.VARIABLE13PERCENTANDHALF]: '13,5%',
  [RATE.CUSTOM]: 'Custom',
  [RATE.DEFAULT]: 'Старый тариф',
  [RATE.SEVENDAYS190AND160]: '7 дней 190/160',
  [RATE.THIRTYDAYSPLUS2PERCENT]: '30 дней +2%',
  [RATE.FOURTEENDAYS250AND190]: '14 дней 250/190',
};

export { RATETYPE as default, RATETYPE, RATE, RURATE };
