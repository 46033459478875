const REPORTTYPE = 'report';
const SERVICETYPE = 'service';
const ALL_TYPES = 'All';
const ACCOUNTANTTYPE = 'bookKeeping';
const CALCULATOR_TYPE = 'calculator';
const MICETYPE = 'mice';
const VACATION_TYPE = 'vacation';

const REPORT_TYPES = {
  Incoming: 'reportIncoming',
  Debtors: 'reportDebtor',
  Airlines: 'reportAirTickets',
  Hotels: 'reportHotels',
  HotelsPk: 'reportHotelsPk',
  Trains: 'reportTrainTickets',
  AirlineCancel: 'airlineCancel',
  Booking: 'reportBooking',
  Proceeds: 'reportProceeds',
  Transactions: 'reportTrip',
  Report1: 'report1',
  Accounts: 'reportAccounts',
  Actions: 'reportManagersActions',
  Overdraft: 'overdraft',
  Overdraft2: 'overdraft2',
  HotelFull: 'reportHotelsSelling',
  EmptyAutocomplete: 'reportEmptyAutocompletes',
  EmptyRates: 'reportEmptyRates',
  ShippingType: 'reportShippingType',
  EditingClosing: 'reportEditingClosing',
  NewUsers: 'analyticsAmo',
  HotelBookWithGrouping: 'groupingHotel',
  CompaniesDaysInDebt: 'reportCompaniesDaysInDebt',
  Insurance: 'insurance',
  ReInsurance: 'reInsurance',
  SupplierDocuments: 'supplierDocuments',
  TripByDate: 'tripByDate',
  CallApartments: 'CallApartments',
  PrintDocuments: 'printDocuments',
  AviaReport: 'aviaReport',
  PackagesChanges: 'reportEditingClosing',
  TaxiReport: 'reportTaxi',
  ForeignHotels: 'reportAboard',
};

const SERVICES_TYPES = {
  IntercomStyleChat: 'reportStyle',
  IntercomTagsChat: 'reportTags',
  IntercomReplyChat: 'reportReply',
  IntercomKpiPOChat: 'reportKpipo',
  IntercomStopWordChat: 'reportStopword',
  IntercomKpiNoteChat: 'reportKpiNote',
  IntercomTimeChat: 'reportTimeChat',
  Consultations: 'reportConsultations',
};

export {
  MICETYPE,
  REPORTTYPE,
  SERVICETYPE,
  ALL_TYPES,
  ACCOUNTANTTYPE,
  REPORT_TYPES,
  SERVICES_TYPES,
  CALCULATOR_TYPE,
  VACATION_TYPE,
};
