const Unavailable = 'Unavailable';
const Available = 'Available';
const OnlyAfterApprove = 'OnlyAfterApprove';
const Unlimited = 'Unlimited';
const Limited = 'Limited';
const TravelPolicy = 'TravelPolicy';
const ApprovalScheme = 'ApprovalScheme';
const PersonalVouchers = 'PersonalVouchers';
const PersonalInvoices = 'PersonalInvoices';
const PersonalVouchersAndInvoices = 'PersonalVouchersAndInvoices';
const AccountVouchers = 'AccountVouchers';
const AccountInvoices = 'AccountInvoices';
const AccountVouchersAndInvoices = 'AccountVouchersAndInvoices';
const All = 'All';
const Personal = 'Personal';
const Undefined = 'Undefined';
const PersonalTrips = 'personalTrips';
const AccountTrips = 'accountTrips';

export const BUYTRIPSPERSONALRIGHT = {
  Unavailable,
  OnlyAfterApprove,
  Limited,
  TravelPolicy,
  Unlimited,
  ApprovalScheme,
};

export const BUYTRIPSACCOUNTRIGHT = {
  Unavailable,
  OnlyAfterApprove,
  TravelPolicy,
  Unlimited,
  ApprovalScheme,
};

export const FINANCEDOCUMENTSRIGHT = {
  Unavailable,
  Available,
};

export const NOTIFICATIONRIGHT = {
  Unavailable,
  PersonalVouchers,
  PersonalInvoices,
  PersonalVouchersAndInvoices,
  AccountVouchers,
  AccountInvoices,
  AccountVouchersAndInvoices,
};

export const APPROVETRIPRIGHT = {
  Unavailable,
  Available,
};

export const EDITACCESSRIGHT = {
  Unavailable,
  Available,
};

export const EDIT_MICE_RIGTHS = {
  Undefined,
  Personal,
  All,
};

export const EDITEMPLOYEERIGHT = {
  All,
  Personal,
  Undefined,
};

export const VIEWTRIPSRIGHT = {
  Undefined,
  Personal,
  All,
};

export const VIEW_EXPENSE_REPORTS_RIGHT = {
  Undefined,
  Available,
};

export const APPROVE_EXPENSE_REPORTS = {
  Unavailable,
  Available,
};

export const CREATE_EXPENCE_REPORTS = {
  Undefined,
  OnlyAfterApprove,
  Unlimited,
  ApprovalScheme,
};

export const COMMONRIGHT = {
  Unavailable,
  OnlyAfterApprove,
  TravelPolicy,
  Unlimited,
  ApprovalScheme,
};

export const DOCUMENTS = {
  PersonalTrips,
  AccountTrips,
};

export const THEMEACTION = {
  DEFAULT: 'default',
  MEDIUM: 'medium',
  DONE: 'done',
  DELETE: 'delete',
  COPY: 'copy',
};

export const STEPSRIGHTS = {
  CREATE: 'create',
  MAIN: 'main',
  LIMIT: 'limit',
  ADMIN: 'admin',
  NOTIFICATION: 'notification',
  COPY: 'copy',
  EDIT: 'edit',
  CLOSE: 'close',
};

export const FIELDRIGHTS = {
  MANUALLY: 'manually',
  COPY: 'copy',
  BUYTRIPPERSONAL: 'buyTripPersonal',
  BUYTRIPACCOUNT: 'buyTripAccount',
  APPROVE: 'approve',
  FINANCE: 'finance',
  BUYUNLIMITED: 'buyUnlimited',
  BUYAFTERAPPROVE: 'buyAfterApprove',
  BUYTRAVELPOLICY: 'buyTravelPolicy',
  BUY_APPROVAL_SCHEME: 'buyApprovalScheme',
  BUYLIMITED: 'buyLimited',
  EDITEMPLOYEE: 'editEmployee',
  VIEWTRIPS: 'viewTrips',
  VIEWEXPENSEREPORTS: 'viewExpenseReports',
  EDITRIGHTS: 'editRights',
  EDITTRAVELPOLICIES: 'editTravelPolicies',
  CANGETVOUCHERS: 'canGetVouchers',
  CANGETINVOICES: 'canGetInvoices',
  SENDDOCUMENTS: 'sendDocuments',
  APPROVERS: 'approvers',
  VOUCHERS: 'vouchers',
  INVOICES: 'invoices',
  EDIT: 'edit',
  CLOSE: 'close',
  CREATEEXPENCEREPORTS: 'createExpenseReports',
  BUY_INSURANCE: 'buyInsurance',
};

export const SHORTFIELDRIGHTS = {
  BUYTRIP: 'buyTrip',
  EDITSELF: 'editSelf',
  DOCUMENTS: 'documents',
  NOTBUYTRIP: 'notBuyTrip',
  APPROVEEXPENSEREPORTS: 'approveExpenseReports',
  SEARCHBESTTRIP: 'searchBestTrip',
  VIEWSELFTRIPS: 'viewSelfTrips',
  MICE_EVENT: 'viewMiceEvent',
};

export const RIGHTSTEXT = {
  SEARCHBESTTRIP: 'Искать лучшие варианты доступных рейсов и гостиниц',
  BUYTRIPPERSONAL: 'Покупать поездки для себя',
  BUYTRIPACCOUNT: 'Покупать поездки для всех сотрудников',
  BUYUNLIMITED: ' без ограничений',
  BUYAFTERAPPROVE: ' по согласованию с авторизующим лицом любым',
  BUYTRAVELPOLICY: ' в рамках Travel политик',
  BUY_APPROVAL_SCHEME: ' в рамках схем согласования',
  BUY_APPROVAL_SCHEME_ONLY: ' в рамках схемы согласования',
  VIEWTRIPS: 'Просматривать чужие поездки',
  VIEWEXPENSEREPORTS: 'Видеть авансовые отчеты всей компании',
  VIEWSELFTRIPS: 'Просматривать свои поездки',
  FINANCE: 'Просматривать финансовую отчетность',
  APPROVE: 'Согласовывать поездки другим сотрудникам',
  EDITSELF: 'Редактировать информацию о себе',
  EDITEMPLOYEE: 'Редактировать информацию обо всех сотрудниках',
  EDITTRAVELPOLICIES: 'Создавать и редактировать Travel политики',
  EDITRIGHTS: 'Предоставлять и ограничивать доступ в сервис другим сотрудникам',
  CANGETVOUCHERS: 'Автоматически получать ваучеры',
  CANGETINVOICES: 'Автоматически получать счета',
  CANGETVOUCHERSANDINVOICES: 'Автоматически получать ваучеры и счета',
  PERSONALTRIPS: ' по приобретенным поездкам на',
  ACCOUNTTRIPS: ' по всем поездкам на',
  SENDDOCUMENTS: 'Автоматически получать закрывающие документы на',
  NOTBUYTRIP: 'Покупать поездки',
  NOTBUYTRIPACCOUNT: 'Покупать поездки для других сотрудников',
  NOTVIEWTRIPS: 'Просматривать все поездки',
  NOTEDITSELF: 'Редактировать информацию о сотрудниках',
  NOTEDITEMPLOYEE: 'Редактировать информацию о других сотрудниках',
  NOPERSONALTRIPS: ' по поездкам, приобретенным другими пользователями на',
  APPROVEEXPENSEREPORTS: 'Согласовывать авансовые отчеты',
  CREATEEXPENCEREPORTS: 'Создавать авансовые отчеты',
  FOR_EMPLOYEE: ' для всех сотрудников ',
  FOR_MYSELF: ' для себя ',
  BUY_INSURANCE: 'Покупать страховые полисы',
  MICE_EVENT: 'Просматривать все заявки на мероприятия',
};

export const EMPTYRIGHTS = {
  ApproveExpenseReports: Unavailable,
  Approve: Unavailable,
  BuyTripAccount: Unavailable,
  BuyTripPersonal: Unavailable,
  CanBook: false,
  CanEditRights: false,
  CanGetDebtNotification: false,
  CanGetInvoiceNotification: 0,
  CanGetNotifications: false,
  CanGetVoucherNotification: 0,
  CanLogin: false,
  CanViewReports: false,
  CreateExpenseReports: Undefined,
  EditEmployee: Undefined,
  EditRights: Unavailable,
  Finance: Unavailable,
  Login: '',
  Notifications: Unavailable,
  PersonalMoneyPerDayBookLimit: 0,
  PersonalNumberPerDayBookLimit: 0,
  ViewTrips: Undefined,
  ViewExpenseReports: Undefined,
};

export const CREATERIGHTS = {
  CanBook: false,
  CanLogin: true,
};

export const DELETERIGHTS = {
  CanBook: true,
  CanLogin: false,
  Login: '',
};

export const EDITRIGHTS = {
  CanBook: true,
  CanLogin: true,
};

export const TEXTBYANY = 'любым';
