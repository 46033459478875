const noNumbers = (/[^0-9+]/);
const zeroToFife = (/^[0-5]?$/);
const noLettersAndNumbers = (/[^a-zA-Zа-яА-ЯёЁ^0-9+]/);
const noPunctuation = (/^[A-Za-zа-яА-ЯёЁ0-9_-]+$/);
const ruChars = /^[А-ЯЁа-яё]*$/;
const enChars = /^[A-Za-z]*$/;
const enCharsWithNumbers = /^[a-zA-Z0-9 ]+$/i;
const ruCharsWithNumbers = /^[А-ЯЁа-яё0-9 ]+$/i;
const numbersAndPoint = (/^[0-9]*[.,]?[0-9]*$/);
const numbersAndColon = (/^[0-9]*[:,]?[0-9]*$/);
const point = (/,/g);
const dash = (/-/g);
const regUrl = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
const float = (/^[0-9]*[.]?[0-9]*$/);
const quotes = (/"/g);
const onlyNumbers = (/^\d+$/);
const timePattern = (/^\d\d:\d\d$/);
const timePatternWithSeparator = (/[^:\d]/g);
const regValidForEmd = /^[а-яА-Яa-zA-Z0-9-]+$/;
const numbersSpaceAndSlash = /^[0-9 _\-/]*$/;

const ONLY_NUMBER_AND_LETTERS = /^[a-zA-Z0-9]+$/;

const REG_AVIA_CONTRACT = {
  SALE_S7: /^QUW[0-9]{4}$/,
  MILE_S7: /^QY[0-9]{5}$/,
  SALE_AEROFLOT: /^CK[0-9]{4}$/,
  MILE_AEROFLOT: /^[0-9]{8}$/,
  SALE_TURKISH: /^[0-9]{6}$|^[A-Z]+[0-9]+$/,
  MILE_U6: /^[0-9]{10}$/,
  SALE_U6: /^[a-zA-Z]+$/,
  SALE_UT: /^CC[0-9]+$/,
};

const airTicketNUmberValidations = {
  base: /^\w{3}-\w{10}$/,
  special: /^\w{3}-\w{10}[-/]\w+$/,
};

export {
  noNumbers,
  onlyNumbers,
  noLettersAndNumbers,
  numbersAndPoint,
  numbersAndColon,
  point,
  dash,
  regUrl,
  zeroToFife,
  float,
  quotes,
  noPunctuation,
  ONLY_NUMBER_AND_LETTERS,
  REG_AVIA_CONTRACT,
  timePattern,
  timePatternWithSeparator,
  ruChars,
  enChars,
  enCharsWithNumbers,
  ruCharsWithNumbers,
  regValidForEmd,
  numbersSpaceAndSlash,
  airTicketNUmberValidations,
};
